import { Component } from '@angular/core';
import {UtilsService} from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Dashboard';
  constructor(private services: UtilsService) {
    const token = this.services.getCookie('token');
    // tslint:disable-next-line:whitespace
    if(token) { this.services.getUserDetails(); }
  }
}
