import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {UtilsService} from '../../services/utils.service';

@Injectable()
export class UserGuard implements CanActivate {

    constructor(public utilsService: UtilsService,
                public router: Router) {

    }

    canActivate(): boolean {
        if (this.utilsService.isUserLoggedIn()) {
            this.router.navigate(['/dashboard'], {replaceUrl: true});
            return false;
        }
        return true;
    }
}
