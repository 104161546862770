import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {UserGuard} from './guards/user/user.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: './pages/main-layout/main-layout.module#MainLayoutModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule',
    canActivate: [UserGuard]
  },
  {
    path: '**',
    loadChildren: './pages/login/login.module#LoginModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
