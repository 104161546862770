import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, TemplatePortal} from '@angular/cdk/portal';

@Injectable()
export class OverlayService {

    overlayRef: any;
    constructor(private overlay: Overlay) {
    }

    open(config: AppOverlayConfig, component: any) {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
        config['positionStrategy'] = positionStrategy;
        // Returns an OverlayRef which is a PortalHost
        this.overlayRef = this.overlay.create(config);

        // Create ComponentPortal that can be attached to a PortalHost
        const componentPortal = new ComponentPortal(component);

        // Attach ComponentPortal to PortalHost
        this.overlayRef.attach(componentPortal);
    }

    close() {
        this.overlayRef.detach();
    }
}

export interface AppOverlayConfig extends OverlayConfig {
}
