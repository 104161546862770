import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {MatSnackBar} from '@angular/material';

import {OverlayService} from '../overlay/overlay.module';
import {ProgressSpinnerComponent} from '../progress-spinner/progress-spinner.module';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public user: any = false;
  // public user: any = true;
  public token: any = false;
  public user_role = false;
  public is_mgt = false;
  public toolbar_title = 'Dashboard';
  public program_groups: any = [];
  public locations: any = [];
  public agents: any = [];
  public districts: any = [];
  public page_size = 60;
  public page_size_options = [20, 40, 60];

  constructor(
              public http: HttpClient,
              public snackBar: MatSnackBar,
              private _location: Location,
              private previewProgressSpinner: OverlayService,
              public router: Router) {
  }

  goBack() {
    this._location.back();
  }

  navigate(title: string, link: string) {
    this.toolbar_title = title;
    this.router.navigate([`/${link}`]);
  }

  public presentToast(msg: string) {
    this.snackBar.open(msg, undefined, {
      duration: 2000,
    });
  }

  /**
   * show loading
   */
  public presentLoading() {
    this.previewProgressSpinner.open({hasBackdrop: true}, ProgressSpinnerComponent);
  }

  /**
   * dismiss loading
   */
  public dismissLoading() {
    this.previewProgressSpinner.close();
  }

  public markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  setCookie(key, value) {
    window.localStorage.setItem(key, value);
  }

  getCookie(key) {
    return window.localStorage.getItem(key);
  }

  deleteCookie(key) {
    window.localStorage.removeItem(key);
  }

  public isUserLoggedIn() {
    const token = this.getCookie('token');
    if (token) {
      return token;
    } else {
      return false;
    }
  }

  public getAllDealers(e){
    console.log(e)
    const request = {
        action_url: '/dealer/all',
        method: 'GET',
        params: {q: e}
      };
      this.doHttp(request).subscribe(
        data => {
          this.agents = data;
        },
        err => {
          this.errorHandler(err);
        }
      );
}

public getAllDistricts(){
  const request = {
      action_url: '/dealer/districts',
      method: 'GET',
      params: {}
    };
    this.doHttp(request).subscribe(
      data => {
        this.districts = data;
      },
      err => {
        this.errorHandler(err);
      }
    );
}

  public getUserDetails() {
    const request = {
      action_url: 'adminProfile',
      method: 'GET',
      params: {}
    };
    this.doHttp(request).subscribe(
      data => {
        this.user = data;
        console.log('service for company', this.user);
      },
      error => {
        console.log(error);
        this.errorHandler(error);
      });
  }

  public logout() {
    this.clearUserData();
    this.gotoHome();
  }

  public clearUserData() {
    this.user = false;
    this.token = false;
    this.deleteCookie('token');
  }

  public gotoHome() {
    this.router.navigate(['login'], {replaceUrl: true}).then(resp => console.log(resp));
  }

  /**
   * Error Handle for Api calss
   */
  public errorHandler(response: Object) {
    console.log(response);
    if (response['status'] === 409) {
      this.presentToast('Email/mobile no already exists');
    } else if (response['status'] === 401) {
      this.presentToast('Session expired');
      this.logout();
    } else {
      if ('error' in response) {
        try {
          const err_res = response['error'];
          console.log(err_res);
          this.presentToast(err_res['msg']);
        } catch (e) {

        }
      }
    }
  }

  getMatData(request): Observable<any> {
    const token = this.getCookie('token');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('user_role', this.user_role.toString());
    headers = headers.append('is_mgt', this.is_mgt.toString());
    if (token) {
      headers = headers.append('token', token);
    }

    let params = new HttpParams();
    // tslint:disable-next-line:forin
    for (const key in request['params']) {
      params = params.append(key, request['params'][key]);
    }
    console.log(params.toString());
    const requestUrl = environment['service_url'] + request['action_url'] + '?' + params.toString();
    return this.http.get(requestUrl, {headers: headers});
  }


  public doHttp(request) {
    console.log(request);
    const token = this.getCookie('token');
    let headers = new HttpHeaders();
    if (!request.file_upload) {
      headers = headers.append('Content-Type', 'application/json');
    }
    headers = headers.append('user_role', this.user_role.toString());
    headers = headers.append('is_mgt', this.is_mgt.toString());
    headers = headers.append('device_uuid', '123456');
    if (token) {
      headers = headers.append('token', token);
    }
    console.log('request.file',request.file);
    if (request.file_upload) {
      const formData: FormData = new FormData();
      for (let key in request.params) {
        formData.append(key, request.params[key]);
      }
      if(Array.isArray(request.file)){
        request.file.forEach(e => {
          formData.append(e.key, e.file);
        });
      }else{
        formData.append('file', request.file);
      }
      console.log(formData);
      if (request.method === 'POST') {
        return this.http.post(
          environment['service_url'] + request.action_url,
          formData,
          {
            headers: headers
          }
        );
      } else if (request.method === 'PUT') {
        return this.http.put(
          environment['service_url'] + request.action_url,
          formData,
          {
            headers: headers
          }
        );
      }
    } else if (request.method === 'GET') {
      let httpParams = new HttpParams();
      Object.keys(request['params']).forEach(key => {
        httpParams = httpParams.append(key, request['params'][key]);
      });
      const queryString = httpParams.toString();
      const params = queryString === '' ? '' : '?' + queryString;
      // console.log(params);
      return this.http.get(
        environment['service_url'] +
        request.action_url +
        params,
        {headers: headers}
      );
    } else if (request.method === 'POST' && !request.file_upload) {
      return this.http.post(
        environment['service_url'] + request.action_url,
        JSON.stringify(request.params),
        {
          headers: headers
        }
      );
    } else if (request.method === 'PUT' && !request.file_upload) {
      return this.http.put(
        environment['service_url'] + request.action_url,
        JSON.stringify(request.params),
        {
          headers: headers
        }
      );
    } else if (request.method === 'DELETE') {
      return this.http.delete(
        environment['service_url'] + request.action_url,
        {
          headers: headers
        }
      );
    }
  }
}
